import store from '@/store'

export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                Convert(inputString) {
                    let modifiedString = inputString.replace(/\b(checkpoints?)\b/gi, match => {
                        return match.toLowerCase() === 'checkpoint' ? 'check' : 'checks'
                    });
                    let assignableNames = this.NamingEnum
                    let assignedNames = store.state.namingConventions.names

                    if (!assignableNames || !assignedNames) {
                        return modifiedString
                    }

                    let stringToReturn = modifiedString

                    let assignableNamesKeysOrdered = Object.keys(assignableNames).sort((a, b) => b.length - a.length)

                    assignableNamesKeysOrdered.forEach(nameKey => {
                        let nameValue = assignableNames[nameKey]
                        if (!(nameKey in assignedNames)) {
                            return
                        }

                        let regex = new RegExp(`\\b${nameValue}\\b`, "gi")
                        let replacement = assignedNames[nameKey]

                        // Replace occurrences of nameValue with replacement
                        stringToReturn = stringToReturn.replace(regex, match => {
                            // Check if the match case is different from the replacement
                            if (match.toLowerCase() === nameValue.toLowerCase()) {
                                // Match found, return the replacement
                                return replacement
                            } else {
                                // Match case differs, return the replacement with the same case as match
                                return replacement.replace(
                                    new RegExp(`(?<!\\p{L})${nameValue}(?!\\p{L})`, "giu"),
                                    m => {
                                        // Ensure the first character is capitalized
                                        return m.charAt(0).toUpperCase() + m.slice(1).toLowerCase()
                                    }
                                )
                            }
                        })
                    })

                    return stringToReturn
                }

            }
        })
    }
}