export default {
  loggedIn() {
    return Boolean(localStorage.getItem('_token'))
  },
  setToken: (token) => new Promise((resolve) => {
    localStorage.setItem('_token', token)
    resolve(token)
  }),
  park() {
    localStorage.setItem('_parkedToken', this.token())
    return this
  },
  hasParked() {
    return Boolean(this.parkedToken())
  },
  unpark() {
    this.setToken(this.parkedToken())
    localStorage.removeItem('_parkedToken')
    return this
  },
  destroyToken() {
    localStorage.removeItem('_token')
    return this
  },
  parkedToken() {
    return localStorage.getItem('_parkedToken')
  },
  token() {
    return localStorage.getItem('_token')
  },
  bearer() {
    return 'Bearer ' + this.token()
  },
  authHeaders() {
    return {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': (this.token()) ? this.bearer() : null
    }
  },
  passportLoginPayload(user) {
    return {
      grant_type: 'password',
      client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
      client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
      username: user.username,
      password: user.password,
      scope: '',
    }
  }
}
