module.exports = {

  namespaced: true,

  state: {
    errors: {
      errors: {},
      message: ''
    }
  },

  mutations: {
    set: (state, payload) => {
      state.errors = payload
    },
    clearField(state, field) {
      delete state.errors.errors[field]
    }, 
    clear(state) {
      state.errors = {
        errors: {},
        message: ''
      }
    } 
  },

  getters: {
    errors: state => state.errors.errors,
    errorMessage: state => state.errors.message
  }
}