export let filtering = {
  created() {
    this.filters = {
      ...this.filters,
      ...this.$route.query
    }
    this.$emit('updated', this.filters)
  },

  methods: {
    clearFilter(filter) {
      this.filters[filter] = null
      this.runFilters()
    },
    filterBy(key, value = '') {
      this.filters[key] = value
      this.$emit('updated', this.filters)
      this.runFilters()
    },
    runFilters() {
      this.$router.push({
        name: this.$route.name,
        query: this.filters
      }).catch(() => {})
    },
  }
}
