export default {
    install(Vue) {
        Vue.mixin({
            data: function() {
                return {
                    get NamingEnum() {
                        return Object.freeze({
                            OVERVIEW: "Overview",
                            CALENDAR: "Calendar",
                            ALL_JOBS: "All Jobs",
                            RECURRING_JOB: "Recurring Job",
                            RECURRING_JOBS: "Recurring Jobs",
                            ASSET_DASHBOARD: "Asset Dashboard",
                            ALL_CLIENTS: "All Clients",
                            CLIENT_SITES: "Client Sites",
                            CHECKLIST: "Checklist",
                            CHECKLISTS: "Checklists",
                            CHECK: "Check",
                            CHECKS: "Checks",
                            JOB_TYPE: "Job Type",
                            JOB_TYPES: "Job Types",
                            ASSET_TYPE: "Asset Type",
                            ASSET_TYPES: "Asset Types",
                            ASSET_GROUP: "Asset Group",
                            ASSET_GROUPS: "Asset Groups",
                            CUSTOM_FIELD: "Custom Field",
                            CUSTOM_FIELDS: "Custom Fields",
                            USER: "User",
                            USERS: "Users",
                            COMPANY: "Company",
                            COMPANIES: "Companies",
                            TRANSLATIONS: "Translations",
                            JOB: "Job",
                            JOBS: "Jobs",
                            SITE: "Site",
                            SITES: "Sites",
                            TECHNICIAN: "Technician",
                            TECHNICIANS: "Technicians",
                            CLIENT: "Client",
                            CLIENTS: "Clients",
                            ASSET: "Asset",
                            ASSETS: "Assets",
                            ZONE: "Zone",
                            ZONES: "Zones",
                            INSPECTIONS: "Inspections",
                            INSPECTION: "Inspection",
                        })
                    }
                }
            }
        })
    }
}

//  Look at creating an enum and using that enum in the components.
//  We need a fallback if the service doesn't return any data, or if there is a key that doesn't exist.
//  we need to migrate to Vue 3.