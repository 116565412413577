import { methods } from '@/utils/mixin-builder'

/**
 * To be used with the closeable prop-set,
 * closes the component.
 */
export let close = methods.make({
  close() {
    this.$emit('close', this.$el)
    if (this.autoclose) {
      this.$el.remove()
      this.$emit('closed')
      this.$destroy()
    }
  }
})
