import { mapGetters } from "vuex";

let errors = {

  computed: {
    errors() {
      return this.$store.getters['billow/error/errors']
    }
  }

}

export default errors