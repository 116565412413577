/**
 * Format a string using named keys.
 * Example: format('My name is :name', { name: 'Mike' })
 * @param {String} input The input string with {named} or :named placeholders
 * @param {Object} replacement The replacement object with named keys
 */
export let format = (input, replacement) => {
  return input.replace(
    /:([a-zA-z0-9$-]+)|{((?:(?!{|}).)+)}/g,
    (...matches) => replacement[matches[1] || matches[2]] || matches[0]
  )
}

export default {
  format
}
