import { props } from '@/utils/mixin-builder'
let { group, make } = props

/**
 * Icon props that are shared between the icon component
 * and the child it renders.
 */

export let shared = group({
  ...make('icon', undefined|String, undefined, true),
  ...make('classes', Array|Object|String),
  ...make('size', Number, 1),
})

export let fontAwesome = group({
  ...make('type', String, 'fas'),
  ...make('mask', String),
  ...make('maskType', String, 'fas'),
  ...make('transform', String)
})

export let zondicons = group({
  // reserved for future use
})

export let heroicons = group({
  // reserved for future use
})

export let bytesize = group({
  ...make('cap', String, 'round'),
  ...make('join', String, 'round'),
  ...make('width', String, '2'),
})

export let feather = group({
  ...make('cap', String, 'round'),
  ...make('join', String, 'round'),
  ...make('width', String, '2'),
})
