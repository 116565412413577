/**
 * Imports
 */

// Installers
import filterInstaller from './src/filters'
import storeInstaller from './src/store'

// Mixins
import queryString from './src/mixins/query-string'
import filterBy from './src/mixins/filter-by'
import errors from './src/mixins/error'

// Utilities
import formData from './src/utilities/form-data'
import cleanQuery from './src/utilities/clean-query'
import stateHelpers from './src/utilities/state-helpers'

// Services
import auth from './src/services/auth'

// Classes
import BaseModel from './src/classes/base-model'

/**
 * Exports
 */

// Installers
export let filters = filterInstaller
export let stores = storeInstaller

// Mixins
export let mixins = {
  queryString,
  filterBy,
  errors
}

// Utilities
export let utilities = {
  formData,
  cleanQuery,
  stateHelpers
}

// Classes
export let classes = {
  BaseModel
}

// Services
export let services = {
  auth
}

export default {
  filters,
  stores,
  mixins,
  utilities,
  classes,
  services
}
