import inflection from 'lodash-inflection'
_.mixin(inflection)

let requireFilter = require.context('.', false, /^\.\/(?!index).*\.js$/)

let install = (Vue, options) => {
  requireFilter.keys().forEach(file => {
    let filterName = file.replace(/^\.\//, '').replace(/\.\w+$/, ''),
      filter = requireFilter(file)
    Vue.filter(filterName, filter.default)
  })
}

export default install
