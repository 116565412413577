import string from '../utilities/string'

// {{ object | format(':first_name is a {role}') }}
// or {{ '{first_name} is a :role' | format(object) }}
// where object = { first_name: 'Mike', role: 'member' }

export default (left, right) => {
  let [format, data] = typeof left === 'string' ? [left, right] : [right, left]
  return string.format(format, data)
}
