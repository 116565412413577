// {{ string | abbreviateCount }}
export default (input, precision = 1) => {
  let prefixes = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'm' },
    { value: 1e9, symbol: 'b' },
    { value: 1e12, symbol: 't' },
    { value: 1e15, symbol: 'p' },
    { value: 1e18, symbol: 'e' },
  ]

  if (input == false) return input

  let tier = prefixes.filter((n) => input >= n.value).pop(),
      fixed = Number((input / tier.value).toFixed(precision))

  return `${fixed}${tier.symbol}`
}
