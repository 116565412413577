import BeforeMiddleware from './src/middleware/before'

let install = (Vue, options = { guards: [] }) => {

  if(!options.router) throw 'Easy Route: You must pass in a vue-router instance'

  if(!options.guards) console.log('Easy Route Warning: There are no guards protecting your routes.')

  let router = options.router

  new BeforeMiddleware(
    router,
    options.guards
  )
}

export {
  RouteGroup,
  Route
} from './src/routing/Router'


export default install


