let queryString = require('querystring')

export default {

  mounted() {
    this.getTab() && this.$store.commit('app/setTab', tab)
  },

  methods: {
    setTab(tab) {
      let { protocol, host, pathname } = location,
        url = `${protocol}//${host}${pathname}?tab=${tab}`
      window.history.pushState({ path: url }, '', url);
      this.$store.commit('app/setTab', tab)
    },
    getTab() {
      return queryString.parse(location.search).tab
    }
  },

  computed: {
    activeTab() {
      return this.$store.getters['app/tab']
    }
  }
}
