/**
 * Create a new FormData class and append everything
 * from the given object to it. Useful for submitting forms
 * containing multipart data, like files.
 * @param {Object} object The object to map to FormData
 */
export default object => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())
