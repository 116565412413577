let filterBy = {

  data: () => ({
    filters: {},
    namespace: ''
  }),

  created() {
    if (this.$route.query.search) this.filterBy('search', this.$route.query.search)
  },

  methods: {
    filterBy(column, value) {
      this.$store.commit(`${this.namespace}/filterBy`, {
        column,
        value
      })
    },
  }

}

export default filterBy