import _set from 'lodash/set'
import _get from 'lodash/get'
import _pull from 'lodash/pull'
import _unset from 'lodash/unset'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'

/**
 * Set a piece of state.
 * @param {String} key
 */
export let set = key => (state, value) => {
  _set(state, key, value)
}

/**
 * Toggle a piece of boolean state.
 * Note: if not boolean primitive, it will become one. Just sayin’.
 * @param {String} key
 */
export let toggle = key => state => {
  _set(state, key, !Boolean(_get(state, key)))
}

/**
 * Push something to an array in state.
 * Note: if not array, you’ll be ignored. Just sayin’.
 * @param {String} key
 */
export let push = key => (state, payload) => {
  let piece = _get(state, key)
  if (_isArray(piece)) {
    piece.push(payload)
  }
}

/**
 * Pull a value from an array.
 * Note: if not array, you’ll be ignored. Just sayin’.
 * @param {String} key
 */
export let pull = key => (state, value) => {
  let piece = _get(state, key)
  if (_isArray(piece)) {
    _pull(piece, value)
  }
}

/**
 * Unset propery at the given path of an object.
 * Note: if not object, you’ll be ignored. Just sayin’.
 * @param {String} key
 */
export let unset = key => (state, property) => {
  let piece = _get(state, key)
  if (_isObject(piece)) {
    _unset(piece, property)
  }
}

export default {
  set,
  toggle,
  push,
  pull,
  unset
}
