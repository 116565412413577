import inflection from 'lodash-inflection'
_.mixin(inflection)

let requireStore = require.context('.', false, /^\.\/(?!index).*\.js$/)

let install = (Vue, options = {}) => {


  requireStore.keys().forEach(file => {
    let storeName = file.replace(/^\.\//, '').replace(/\.\w+$/, ''),
      storeModule = requireStore(file)
    if (options && options.store) {
      options.store.registerModule(`billow/${storeName}`, storeModule)
    }  
  })


}

export default install
