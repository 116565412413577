export default {
    install(Vue) {
        Vue.prototype.$n = function (key) {
            const namingConventions = this.$store?.state.namingConventions.names
            let preparedName = key.toUpperCase().replace(' ', '_')
            if (namingConventions && preparedName in namingConventions) {
                return namingConventions[preparedName]
            }
            return key
        }
    }
}