export default class Before {

  constructor(router, guards = []) {
    this.router = router
    this.guards = guards

    this.guards.forEach(guard => {
      if(!guard.hasOwnProperty('name') || !guard.hasOwnProperty('run')) throw 'Easy Route: Guard is missing the name and/or run property. Please see docs for formatting.'
    })

    this.init()
  }

  init() {
    this.router.beforeEach((to, from, next) => {      
      let processes = this.getRouteGuards(to.meta.middleware)
        .reduce((promiseChain, currentTask) => promiseChain.then(() => currentTask.run({to, from, next})), Promise.resolve([]))
      
        processes.then(() => next()).catch(error => {
          // Stops the browser from melting down when guards conflict on routes.
          if (to.name === error.name || to.path === error.path) throw 'Easy Route Error: Infinite loop detected'
          
          next(error)
        })
    })
  }

  getRouteGuards(routeMiddleware = '') {
    return this.guards.filter(guard => {
      return routeMiddleware.indexOf(guard.name) > -1
    })
  }
}