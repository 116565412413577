import { union } from 'lodash';

export let Route = (path = '', name = '', component = {}, middleware = []) => ({
  path: path,
  name: name,
  component: component,
  meta: { middleware },
  children: []
})

class RouterGroup {

  constructor(config = {
      routes: [],
      middleware: [],
      parent: null,
      prefix: ''
    }) {
    this.setPrefix(config.prefix)  
    
    this.middleware = config.middleware  
    this.routes = config.routes
    this.hasRoutes()  
    this.processRoutes(config)
  }

  hasRoutes() {
    if(!this.routes || !this.routes.length) throw "Easy Route: No Routes in Route Group"
  }

  processRoutes(config) {
    if(config.parent) {
      let parent = config.parent
      parent.children = this.mapRoutes(config.routes)
      parent.name = ''
      this.routes = parent
    } else {
      this.routes = this.mapRoutes(config.routes)
    } 
  }

  mapRoutes(routes = []) {

    return routes.map(route => {
      route.path = this.prefix + route.path
      route.meta.middleware = union(this.middleware, route.meta.middleware)
      return route
    })
  }

  setPrefix(prefix) {
    if (typeof prefix === 'undefined' || prefix === null) {
      this.prefix = ''
      return
    }
    this.prefix = prefix
  }
}

export let RouteGroup = (config = {
  routes: [],
  middleware: [],
  parent: null
}) => {
  return (new RouterGroup(config)).routes
}