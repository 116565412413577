export default {
    install(Vue) {
        Vue.mixin({
            computed: {
                Naming() {
                    return Object({
                        Overview: this.namingConventionBase(this.NamingEnum.OVERVIEW),
                        Calendar: this.namingConventionBase(this.NamingEnum.CALENDAR),
                        AllJobs: this.namingConventionBase(this.NamingEnum.ALL_JOBS),
                        RecurringJob: this.namingConventionBase(this.NamingEnum.RECURRING_JOB),
                        RecurringJobs: this.namingConventionBase(this.NamingEnum.RECURRING_JOBS),
                        AssetDashboard: this.namingConventionBase(this.NamingEnum.ASSET_DASHBOARD),
                        AllClients: this.namingConventionBase(this.NamingEnum.ALL_CLIENTS),
                        ClientSites: this.namingConventionBase(this.NamingEnum.CLIENT_SITES),
                        Checklist: this.namingConventionBase(this.NamingEnum.CHECKLIST),
                        Checklists: this.namingConventionBase(this.NamingEnum.CHECKLISTS),
                        Check: this.namingConventionBase(this.NamingEnum.CHECK),
                        Checks: this.namingConventionBase(this.NamingEnum.CHECKS),
                        JobType: this.namingConventionBase(this.NamingEnum.JOB_TYPE),
                        JobTypes: this.namingConventionBase(this.NamingEnum.JOB_TYPES),
                        AssetType: this.namingConventionBase(this.NamingEnum.ASSET_TYPE),
                        AssetTypes: this.namingConventionBase(this.NamingEnum.ASSET_TYPES),
                        AssetGroup: this.namingConventionBase(this.NamingEnum.ASSET_GROUP),
                        AssetGroups: this.namingConventionBase(this.NamingEnum.ASSET_GROUPS),
                        CustomField: this.namingConventionBase(this.NamingEnum.CUSTOM_FIELD),
                        CustomFields: this.namingConventionBase(this.NamingEnum.CUSTOM_FIELDS),
                        User: this.namingConventionBase(this.NamingEnum.USER),
                        Users: this.namingConventionBase(this.NamingEnum.USERS),
                        Company: this.namingConventionBase(this.NamingEnum.COMPANY),
                        Companies: this.namingConventionBase(this.NamingEnum.COMPANIES),
                        Translations: this.namingConventionBase(this.NamingEnum.TRANSLATIONS),
                        Job: this.namingConventionBase(this.NamingEnum.JOB),
                        Jobs: this.namingConventionBase(this.NamingEnum.JOBS),
                        Site: this.namingConventionBase(this.NamingEnum.SITE),
                        Sites: this.namingConventionBase(this.NamingEnum.SITES),
                        Technician: this.namingConventionBase(this.NamingEnum.TECHNICIAN),
                        Technicians: this.namingConventionBase(this.NamingEnum.TECHNICIANS),
                        Client: this.namingConventionBase(this.NamingEnum.CLIENT),
                        Clients: this.namingConventionBase(this.NamingEnum.CLIENTS),
                        Asset: this.namingConventionBase(this.NamingEnum.ASSET),
                        Assets: this.namingConventionBase(this.NamingEnum.ASSETS),
                        Zone: this.namingConventionBase(this.NamingEnum.ZONE),
                        Zones: this.namingConventionBase(this.NamingEnum.ZONES),
                        Inspections: this.namingConventionBase(this.NamingEnum.INSPECTIONS),
                        Inspection: this.namingConventionBase(this.NamingEnum.INSPECTION),
                    })
                }
            },
            methods: {
                namingConventionBase(value) {

                    const namingConventions = this.$store?.state.namingConventions.names
                    let preparedName = value.toUpperCase().replace(' ', '_')
                    if (namingConventions && preparedName in namingConventions) {
                        return namingConventions[preparedName]
                    }
                    return value
                }

            },

        })
    }
}