import { computed } from '@/utils/mixin-builder'
import stringHelper from '@/utils/string'

/**
 * Determines if an error is present. An error may either be
 * an Array or String. If it is an array, the FieldError
 * component will select the first error in it.
 */
export let hasError = computed.make({
  hasError() {
    return Boolean(this.error) && (
      this.error instanceof Array && this.error.length
      || typeof this.error === "string"
    )
  }
})

/**
 * Random field identifier for when field-id is not passed
 * to a component that replies on it.
 */
export let identifiesFields = computed.make({

  /**
   * Generate a random identifier.
   */
  randomIdentifier() {
    return stringHelper.random()
  },

  /**
   * Use a field ID or a random identifier to
   * identify a field.
   */
  fieldIdentifier() {
    return this.fieldId || this.randomIdentifier
  },

  /**
   * Generate an identifier using slug or camel formats.
   */
  generatedIdentifier() {
    return (label = '', type = 'slug', prependRandom = false) =>
      (prependRandom ? `${this.randomIdentifier}_` : '') +
      stringHelper[type](label || this.fieldId || this.randomIdentifier)
  },
  slugIdentifier() {
    return (label = '', prependRandom = false) => this.generatedIdentifier(label, 'slug', prependRandom)
  },
  camelIdentifier() {
    return (label = '', prependRandom = false) => this.generatedIdentifier(label, 'camel', prependRandom)
  },
})

/**
 * To be used with the closeable prop-set,
 * determine if the component can be closed.
 */
export let canClose = computed.make({
  canClose() {
    return this.closeable || this.autoclose
  }
})

/**
 * Common computed props for getting icons from libraries.
 */
export let usesIconLibraries = computed.make({
  library() {
    return this.$vueBulma.icons[this.useLibrary] || {}
  },
  normalisedName() {
    return stringHelper.camel(this.icon)
  },
  exists() {
    return this.library.hasOwnProperty(this.normalisedName)
  },
  fromLibrary() {
    return this.library[this.normalisedName]
  },
  content() {
    return this.exists ? this.fromLibrary : this.fallback
  }
})
