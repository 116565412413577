export default class BaseModel {

  /**
   * Hydrate a model from an object store.
   * @param {Object} object
   */
  hydrate(object) {
    for (let key in object) {
      this[key] = object[key]
    }

    return this
  }

  /**
   * Mop up object values that need to be primitive
   * before used. Returns a new instance of the model.
   * @param {Object} moppable
   */
  mop(moppable) {
    let data = { ...this }
    for (let key in moppable) {
      if (data[key] && data[key] instanceof Object) {
        data[key] = data[key][moppable[key]]
      }
    }

    return data
  }

}
